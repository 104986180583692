import React, { useEffect, useState } from "react";
import { Popover, Empty } from "antd";
import notification from "./../../../assets/icon/notification.svg"
import { phoneNumber, token } from "../../../configs/localStorage";
import { lengthNotification, listNotify } from "../../../apis/apis";

import mqttClient from "../../../configs/server"

import nhietke1 from "./../../../assets/img/nhietke2.png"
import nhietke2 from "./../../../assets/img/nhietke3.png"
import nhietke0 from "./../../../assets/img/nhietke1.png"
import Voltate0 from "./../../../assets/img/Voltate0.png"
import Voltate1 from "./../../../assets/img/Voltate1.png"
import Voltate2 from "./../../../assets/img/Voltate2.png"
import curent0 from "./../../../assets/img/curent0.png"

import curent1 from "./../../../assets/img/curent1.png"
import curent2 from "./../../../assets/img/curent2.png"
import mqtt from 'mqtt';
import { Notification } from "../../../configs/notification";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setNotifi } from "../../../redux/slice/notificaition";
const NotificationCpn = () => {
    const divRef = React.useRef(null)
    const [dataNotify, setDataNotify] = useState([])
    const [openPopoverNotification, setOpenPopoverNotification] = useState(false);

    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [notSeen, setNotSeen] = useState(0)
    const [lengthNoti, setLengthNoti] = useState<any>()
    // const [client, setClient] = useState(null);<any></any>
    const formatDate = (date: any, time?: any) => {
        const parts = date?.split(' ');
        if (parts) {
            const datePart = parts[0];
            const timePart = parts[1];
            const dateParts = datePart.split('-');
            const year = dateParts[0];
            const month = dateParts[1];
            const day = dateParts[2];
            const formattedDate = `${day}/${month}/${year}`;
            if (time) {
                return `${timePart}`;
            }
            return formattedDate
        }

    }
    const renderImg = (name: any, level: any) => {

        if (name === "0") {
            if (level == 0) {
                return Voltate0
            }
            if (level == 1) {
                return Voltate1
            }
            return Voltate2
        }
        if (name === "1") {
            if (level == 0) {
                return curent0
            }
            if (level == 1) {
                return curent1
            }
            return curent2
        }
        if (name === "2") {
            if (level == 0) {
                return nhietke0
            }
            if (level == 1) {
                return nhietke1
            }
            return nhietke2
        }
    }
    const handleScroll = () => {
        const divElement: any = divRef.current;


        if (divElement.scrollTop + divElement.clientHeight >= divElement.scrollHeight && pageSize < lengthNoti && !loading) {
            setPageSize((prevPage) => prevPage + 10);
            getListNotify(pageSize + 10)
        }
    };
    useEffect(() => {
        const divElement: any = divRef.current;

        if (divElement) {
            divElement.addEventListener('scroll', handleScroll);
        }



        return () => {
            if (divElement) {
                divElement.removeEventListener('scroll', handleScroll);
            }

        };
    }, [handleScroll]);
    // useEffect(() => {
    //     getListNotify()
    // }, [pageSize, statusNotification])
    const getListNotify = (pageSizeProp: any) => {
        setLoading(true)
        const tokenFormat = new FormData();
        tokenFormat.append("token", `${token}`);
        tokenFormat.append("pagenb", `${page - 1}`);
        tokenFormat.append("perpage", `${pageSizeProp}`);
        listNotify(tokenFormat)
            .then((res: any) => {

                if (res?.data?.status === 0) {
                    setDataNotify(res?.data?.message)


                    setLengthNoti(res?.data?.length)

                    setLoading(false)
                }
            })
            .catch((e) => {
                console.log("e", e)
            })

    }
    const handleOpenChangeNotification = async (newOpen: boolean) => {

        setOpenPopoverNotification(newOpen)
        getListNotify(pageSize)
        setNotSeen(0)


    }
    useEffect(() => {
        const tokenFormat = new FormData();
        tokenFormat.append("token", `${token}`);
        lengthNotification(tokenFormat)
            .then((res: any) => {
                setNotSeen(res?.data?.length_created)
            })

    }, [])
    useEffect(() => {
        mqttClient.on('connect', () => {
            mqttClient.subscribe(`${phoneNumber}`);
        });

        mqttClient.on('message', (topic: any, message: any) => {
            const strFromCharCode: any = String.fromCharCode.apply(null, message);
            const format = JSON.parse(strFromCharCode)
            if (format.messagenb !== notSeen) {
                console.log("format.messagenb", topic + format.messagenb)
                setNotSeen(format.messagenb)
                Notification("info", "Bạn có một thông báo mới")

            }

        });
        return () => {
            mqttClient.unsubscribe(`${phoneNumber}`);
        };
    }, []);

    const dispatch = useDispatch()
    // Only do this when the component unmounts

    const contentNotification = (
        !!dataNotify.length
            ?
            <ul ref={divRef} className=" shadow-[0px_4px_4px_0px_rgba(0, 0, 0, 0.25)] shadow-2xl   rounded-[12px] max-h-[700px] overflow-y-auto ">
                {
                    dataNotify.map((item: any, index: any) => (

                        <li key={index + 100} className={` rounded-[12px]  flex gap-2 w-[480px] justify-between   ${item?.status === "read" ? "bg-[rgb(226,237,228,0.5)]  " : "bg-[#E5ECF6]"} px-3 py-1.5 items-center border-b border-[rgba(126,126,126,0.50)]   `}>
                            <img className=" shadow-[0px_4px_4px_rgba(0,0,0,0.25)] rounded-[50%] w-[50px] h-[50px]" src={renderImg(item.type, item.level)} alt="" />
                            <div className=" w-full ">
                                <div className="flex gap-1 text-[#757575] ">
                                    <span className="text-[12px] text-Roboto ">{formatDate(item?.time)}</span>
                                    <span className="text-[12px] text-Roboto">{formatDate(item?.time, true)}</span>
                                </div>
                                <div className=""  >
                                    <div className=" text-Roboto flex flex-wrap border-b border-[#C3DEC6] ">
                                        Thiết bị
                                        <Link to={"/log-device"} > <span onClick={() => {
                                            dispatch(setNotifi(item?.serinb))
                                        }} className=" mx-1.5 text-[#3772D4] underline ">{item?.serinb}</span>
                                        </Link>
                                        SĐT :
                                        <Link to={"/user-manage"} >
                                            <span onClick={() => {
                                                dispatch(setNotifi(item?.phone_notify))
                                            }} className=" mx-1.5 text-[#3772D4] underline ">{item?.phone_notify}</span>
                                        </Link>
                                    </div>
                                    <Link onClick={() => {
                                        dispatch(setNotifi(item?.serinb))
                                    }} to={"/log-device"}><p className=" min-h-[50px]  whitespace-normal text-Roboto">{item?.notify}</p></Link>
                                </div>
                            </div>
                            {
                                item?.status !== "read"
                                    ?
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                                        <circle cx="7.5" cy="7.5" r="7.5" fill="#3772D4" />
                                    </svg>
                                    :
                                    <div className="min-w-[15px] min-h-[15px] "></div>
                            }

                        </li>

                    ))
                }

            </ul>
            :
            <div className="min-w-[430px] min-h-[400px] flex items-center justify-center flex-col">
                <Empty className="w-full h-full" description={false} />
                <h4 >Không có thông báo !</h4>
            </div>

    )
    return (
        <Popover
            open={openPopoverNotification}
            onOpenChange={handleOpenChangeNotification}
            content={contentNotification} trigger="click"
            placement="bottomRight"

        >
            <img src={notification} alt="" />
            <div className="absolute w-[20px] h-[20px]  text-[14px]  rounded-[50%] bg-[rgba(52,100,172,0.80)] text-[#fff] top-[-8px] left-[25px]  flex justify-center items-center ">{notSeen > 99 ? "99" : notSeen}</div>
        </Popover>
    )
}
export default NotificationCpn