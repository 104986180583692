import axios from "axios";
import { Notification } from "./notification";
export const baseServer = "http://acquy-vision.vn:8082"
export const instanceAxios = axios.create({
    baseURL: `${baseServer}/api`
})
instanceAxios.interceptors.response.use((response) => response,
    async (error) => {
        console.log('ERR', error)
        if (error.code === "ERR_NETWORK") {
            Notification("error", `Server đang gặp trục chắc vui lòng thử lại sau `);
            return
        }
        if (error?.response?.status >= 500) {

            console.error('Lỗi server:', error);
            return
        }
        if (error?.response?.status === 401) {
            localStorage.clear()
            window.location.href = "/login"
        }

    }
)