import { createSlice } from '@reduxjs/toolkit'


export interface InfoAdmin {
    InfoAdmin: any,
    phone: any

}

const initialState: InfoAdmin = {
    InfoAdmin: [],
    phone: ""

}

export const InfoAdminSlice = createSlice({
    name: 'InfoAdmin',
    initialState,
    reducers: {
        setInfoAdmin: (state, action) => {
            state.InfoAdmin = action.payload
        },
        setPhone: (state, action) => {
            state.phone = action.payload
        },

    },
})

// Action creators are generated for each case reducer function
export const { setInfoAdmin, setPhone } = InfoAdminSlice.actions

export default InfoAdminSlice.reducer