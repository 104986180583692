import React, { useEffect, useState } from "react";
import Logo from "./logo";
import { logout, profile } from "../../../apis/apis";
import { phoneNumber, token } from "../../../configs/localStorage";


import menu from "./../../../assets/icon/menu.svg"
import { Popover } from "antd";
import { Notification } from "../../../configs/notification";
import { useNavigate } from "react-router-dom";
import ModalChangePassword from "./Modal/ModalChangePassword";
import { setInfoAdmin } from "../../../redux/slice/InfoAdmin";
import { useDispatch } from "react-redux";

import NotificationCpn from "./Notification";

const Header = () => {
    const [ProfileUser, setProfileUser] = useState<any>()
    const [openPopover, setOpenPopover] = useState(false);

    const [openModalChangePassword, setOpenModalChangePassword] = useState(false)

    const dis = useDispatch()
    useEffect(() => {
        const formData = new FormData();

        formData.append("phonenb", `${phoneNumber}`);
        formData.append("token", `${token}`);

        if (token) {
            profile(formData)
                .then((res) => {

                    setProfileUser(res?.data?.message)
                    dis(setInfoAdmin(res?.data?.message))

                }).catch((e) => {
                    console.log(e)
                })

        }
    }, [])







    const handleOpenChange = (newOpen: boolean) => {
        setOpenPopover(newOpen);
    }



    const Navigate = useNavigate()
    const handleLogout = () => {
        const formData = new FormData();
        formData.append("phonenb", `${phoneNumber}`);
        formData.append("token", `${token}`);
        logout(formData)
            .then((res) => {

                Notification("success", res?.data.message)
                setTimeout(() => {
                    localStorage.clear()
                    Navigate("/login")
                }, 2000);
            })
        handleOpenChange(false)
    }

    const content = (
        <div className="flex flex-col  items-end p-3">
            <button onClick={() => {
                handleOpenChange(false)
                setOpenModalChangePassword(true)
            }

            } className="border-b-2 pb-3  border-[rgba(128,128,128,0.50)]">Change Password</button>
            <button className="pt-3" onClick={handleLogout}>Log Out</button>
        </div >

    )


    return (
        <div className="min-h-[70px]  pt-4 px-4 flex justify-between items-center ">
            <div>
                <Logo />
            </div>
            <div className="flex items-center gap-4 mr-10">
                <h4 className=" text-[20px] font-normal ">{ProfileUser?.fullname}</h4>
                <div className="relative ">

                    <NotificationCpn />
                </div>
                <Popover open={openPopover}
                    onOpenChange={handleOpenChange}
                    content={content} trigger="click"
                    placement="bottomRight">
                    <img className="cursor-pointer  w-[35px] " src={menu} alt="" />
                </Popover>

                <ModalChangePassword openModalChangePassword={openModalChangePassword} setOpenModalChangePassword={setOpenModalChangePassword} />
            </div>

        </div>
    )
}
export default Header