
import { instanceAxios } from "../configs/axios";

export const login = (body: any) => instanceAxios.post("/gettokenadmin", body)
export const profile = (body: any) => instanceAxios.post("/getuserinfo", body)
export const logout = (body: any) => instanceAxios.post("/logout", body)
export const changePassword = (body: any) => instanceAxios.post("/changepassadmin", body)
export const OTP = (body: any) => instanceAxios.post("/getotp", body)
export const getToken = (body: any) => instanceAxios.post("/gettoken", body)
export const listDevice = (body: any) => instanceAxios.post("/listdevice", body)
export const deleteDevice = (body: any) => instanceAxios.post(`/deldevice`, body)
export const deleteUser = (body: any) => instanceAxios.post(`/deluser`, body)

export const listUserDevice = (body: any) => instanceAxios.post("/listuser", body)

export const listDevListLog = (body: any) => instanceAxios.post("/devlistlog", body)
export const updateUserInfo = (body: any) => instanceAxios.post("/updateuserinfo", body)
export const upRole = (body: any) => instanceAxios.post("/roleup", body)
export const downRole = (body: any) => instanceAxios.post("/roledown", body)

export const deviceInfo = (body: any) => instanceAxios.post("/deviceinfo", body)
export const upDateType = (body: any) => instanceAxios.post("/updateconfig", body)

export const listNotify = (body: any) => instanceAxios.post("/listnotify", body)



export const configDevice = (body: any) => instanceAxios.post("/configdevice", body)
export const logindevice = (body: any) => instanceAxios.post("/logindevice", body)
export const createuser = (body: any) => instanceAxios.post("/createuser", body)

export const lengthNotification = (body: any) => instanceAxios.post("/nbnotifycreated", body)
