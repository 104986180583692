import { createSlice } from '@reduxjs/toolkit'


export interface Notifi {
    Notifi: any,


}

const initialState: Notifi = {
    Notifi: "",


}

export const NotifiSlice = createSlice({
    name: 'Notifi',
    initialState,
    reducers: {
        setNotifi: (state, action) => {
            state.Notifi = action.payload
        },


    },
})

// Action creators are generated for each case reducer function
export const { setNotifi } = NotifiSlice.actions

export default NotifiSlice.reducer