
import mqtt from 'mqtt';
import { phoneNumber } from './localStorage';



const mqttBrokerUrl = `ws://mqtt.autotron.vn:9001/`;

const connectOptions = {
    keepalive: 2000,
    username: '',
    password: ''

};


const notificationClient = mqtt.connect(mqttBrokerUrl, connectOptions);


export default notificationClient;

